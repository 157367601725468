<template>
    <div class="flex items-center h-8">
        <div class="flex-1 flex justify-center items-center" v-on-clickaway="accept" v-if="editing">
            <label class="flex-1 ml-12" v-show="label">
                {{ label }}
            </label>

            <activix-masked-input
                :mask-type="maskType"
                :input-class="inputClassGenerated"
                :disabled="disabled"
                :integer-limit="integerLimit"
                :placeholder="placeholder"
                ref="maskedInput"
                @keyup.enter.native="accept"
                @keyup.esc.native="cancel"
                v-model="val"
            />

            <activix-button
                class="ml-2 shadow h-8 w-9 p-0 flex-shrink-0"
                type="primary"
                size="small"
                @click="accept"
            >
                <icon name="bold/check-1" class="text-xs" />
            </activix-button>

            <activix-button class="ml-2 shadow h-8 w-9 p-0 flex-shrink-0" size="small" @click="cancel">
                <icon name="bold/close" class="text-xs text-grey-650" />
            </activix-button>
        </div>
        <span
            class="flex-1"
            :class="editionClasses"
            @click="editField"
            v-if="!editing"
        >
            <span v-if="hasValue">
                <span v-if="type == 'currency'">{{ toMoney(val, 0, $i18n.locale, false) }}</span>
                <span v-else>
                    {{ val }}
                </span>
            </span>
            <span v-else>-</span>
        </span>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        props: {
            name: {
                type: String,
                default: '',
            },
            customName: {
                type: String,
                default: '',
            },
            integerLimit: {
                type: Number,
                default: 10,
            },
            value: {
                type: [String, Number],
                default: '',
            },
            placeholder: {
                type: String,
                default: '',
            },
            type: {
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            valid: {
                type: Boolean,
                default: true,
            },
            emptyValue: {
                type: String,
                default: '...',
            },
            label: {
                type: String,
                default: '',
            },
            inputClass: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                val: '',
                editing: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['configs']),

            hasValue() {
                return this.val != '' && this.val != 0 && !!this.val;
            },

            editionClasses() {
                return {
                    'opactiy-50': this.disabled,
                    'cursor-pointer': !this.disabled,
                };
            },

            maskType() {
                if (this.type == 'currency') {
                    return 'money';
                }

                if (this.type == 'numeric') {
                    return 'number';
                }

                return 'custom';
            },

            inputClassGenerated() {
                return `input-sm ${this.inputClass}`.trim();
            },
        },

        watch: {
            value() {
                this.val = !Number.isNaN(this.value) ? parseInt(this.value, 10) : '';
            },
        },

        methods: {
            async editField() {
                if (this.disabled) {
                    return;
                }

                this.editing = true;

                await this.$nextTick();

                this.$refs.maskedInput.focus();
            },

            accept() {
                this.$emit('accepted', this.val);
                this.editing = false;
            },

            cancel() {
                this.val = this.value;
                this.editing = false;
            },
        },

        async mounted() {
            await this.$nextTick();

            if (!empty(this.value)) {
                this.val = this.value;
            }
        },
    };
</script>
