<template>
    <div class="flex flex-col">
        <warning
            :icon="$icons.warning"
            :title="$t('error.warning')"
            :content="$t('dashboards.disabledForGroups')"
            :show-home="true"
            :show-back="true"
            v-if="dashboardDisabledForGroups"
        />
        <warning
            icon="regular/target-center-1"
            :title="$t('error.warning')"
            :content="$t('error.noSaleTable')"
            :show-back="true"
            :show-home="true"
            v-else-if="!contextAccount.sale_table"
        />
        <template v-else>
            <portal to="content-header-left">
                <activix-reload :loading="isLoading" @click="fetchUsers" />
            </portal>

            <manage-sale-table
                :users="users"
                :class="{ 'opacity-50': $wait.is('fetching.objectives') }"
                @fetch-users="fetchUsers"
                v-if="contextAccount.sale_table"
            />
        </template>
    </div>
</template>

<script>
    import { get } from 'lodash-es';
    import { mapState } from 'pinia';

    // Entities
    import DashboardType from '../../entities/DashboardType.js';

    // Mixins
    import TrackView from '../../mixins/TrackView.js';

    // Components
    import ManageSaleTable from '../../components/sale_table/ManageSaleTable.vue';
    import Warning from '../../components/Warning.vue';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            ManageSaleTable,
            Warning,
        },

        mixins: [TrackView],

        data() {
            return {
                users: [],
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextChildAccount: 'contextChildAccount',
                contextAccount: 'account',
            }),
            ...mapState(useDashboardStore, ['dashboardDisabledForGroups']),

            validAccount() {
                return get(this.contextChildAccount, 'id');
            },

            isLoading() {
                return this.$wait.is(['fetching.contextAccount', 'fetching.objectives']);
            },
        },

        watch: {
            validAccount: {
                immediate: true,
                handler() {
                    if (this.validAccount) {
                        this.fetchUsers();
                    }
                },
            },
        },

        methods: {
            async fetchUsers() {
                this.$wait.start('fetching.objectives');

                try {
                    this.users = await this.$api.users.index({
                        filter: {
                            accountId: this.contextAccount.id,
                        },
                        include: ['account', 'objectives', 'divisions'],
                    });

                    this.$wait.end('fetching.objectives');
                } catch (error) {
                    this.$wait.end('fetching.objectives');
                    this.$notify.error(this.$t('objectives.alerts.index.error'));

                    throw error;
                }
            },
        },

        created() {
            useDashboardStore().dashboardType = DashboardType.SALE;
        },

        beforeDestroy() {
            this.$wait.end('fetching.objectives');
        },
    };
</script>
