<template>
    <div id="manage-sale-table">
        <portal to="content-header-right">
            <div class="flex items-center mx-4">
                <div>
                    <activix-tooltip :content="$t('saleTable.monthStartDayTooltip')">
                        <el-select class="white xs:w-20" v-model="monthStartDay">
                            <el-option
                                :label="day"
                                :value="day"
                                :key="day"
                                v-for="day in days"
                            />
                        </el-select>
                    </activix-tooltip>
                </div>
                <div class="ml-2">
                    <el-select class="white w-48" v-model="selectedMonth">
                        <el-option
                            :class="{
                                'opacity-50':
                                    (currentMonth != 11 && currentMonth > index) ||
                                    (currentMonth == 11 && index != 0 && index != 11),
                            }"
                            :label="`${$t('saleTable.months.' + month)} - ${
                                currentMonth == 11 && index == 0 ? nextYear : nextYear - 1
                            }`"
                            :value="currentMonth == 11 && index == 0 ? 12 : index"
                            :key="month"
                            v-for="(month, index) in months"
                        />
                    </el-select>
                </div>
                <div class="ml-2">
                    <el-select class="white xs:w-32" v-model="selectedDivision">
                        <el-option
                            :label="$t(`saleTable.${name}`)"
                            :value="id"
                            :key="id"
                            v-for="(name, id) in divisions"
                        />
                    </el-select>
                </div>
            </div>
        </portal>

        <div class="box">
            <div class="box-body" :class="{ 'p-0': lgLayout }">
                <div class="overflow-x-auto">
                    <table class="table table-bordered table-striped | border">
                        <thead>
                            <tr>
                                <th>{{ $t('saleTable.user') }}</th>
                                <th>{{ $t('saleTable.saleAmmount') }}</th>
                                <th>{{ $t('saleTable.averageValue') }}</th>
                                <th>{{ $t('saleTable.teamAverage') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :key="index" v-for="(user, index) in filteredUsers">
                                <td class="whitespace-nowrap">
                                    {{ getFullName(user) }}
                                </td>
                                <td class="text-center">
                                    <text-input
                                        custom-class="sale_amount"
                                        name="sale_amount"
                                        :integer-limit="3"
                                        type="numeric"
                                        :value="objective(user).sale_amount"
                                        ref="saleInputs"
                                        @accepted="onAccept(user, 'sale_amount', $event)"
                                    />
                                </td>
                                <td class="text-center">
                                    <text-input
                                        custom-class="average_value"
                                        name="average_value"
                                        type="currency"
                                        :value="objective(user).average_value"
                                        ref="currencyInputs"
                                        @accepted="onAccept(user, 'average_value', $event)"
                                    />
                                </td>
                                <td class="text-center">
                                    {{ percentSale(user) }}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>{{ $t('saleTable.totalAvePct') }}</th>
                                <th class="text-center">
                                    {{ $t('saleTable.table.total') }}: {{ totalSales }}
                                </th>
                                <th class="text-center">
                                    {{ $t('saleTable.table.saleAverage') }}: {{ toMoney(totalAvgValue, 0) }}
                                </th>
                                <th class="text-center">
                                    {{ totalSales > 0 ? '100%' : '0%' }}
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // Components
    import { mapActions, mapState } from 'pinia';
    import TextInput from '../inputs/TextInput.vue';
    import Division from '../../entities/Division.js';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: { TextInput },

        props: {
            users: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                table: null,
                currentMonth: now().month(),
                selectedMonth: now().month(),
                monthStartDay: 1,
                months: [
                    'january',
                    'february',
                    'march',
                    'april',
                    'mai',
                    'june',
                    'july',
                    'august',
                    'september',
                    'october',
                    'november',
                    'december',
                ],
                days: [],
                nextYear: now().year() + 1,
                selectedDivision: null,
                divisions: {
                    [Division.NEW]: 'new',
                    [Division.USED]: 'used',
                },
                filteredUsers: [],
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            totalSales() {
                return this.filteredUsers.reduce((totalSales, user) => {
                    totalSales += this.objective(user).sale_amount;
                    return totalSales;
                }, 0);
            },

            totalAvgValue() {
                const totalAvgValue = this.filteredUsers.reduce(
                    (totalAvgValue, user) => {
                        const userAvg = this.objective(user).average_value;

                        if (userAvg) {
                            totalAvgValue.count++;
                            totalAvgValue.total += userAvg;
                        }

                        return totalAvgValue;
                    },
                    { count: 0, total: 0 },
                );

                return totalAvgValue.count ? totalAvgValue.total / totalAvgValue.count : 0;
            },
        },

        watch: {
            users: {
                immediate: true,
                handler() {
                    this.setFilteredUsers();
                },
            },

            'contextAccount.month_start_day': {
                immediate: true,
                handler(monthStartDay) {
                    if (monthStartDay) {
                        this.monthStartDay = monthStartDay;
                    }
                },
            },

            monthStartDay(monthStartDay) {
                if (monthStartDay != this.contextAccount.month_start_day) {
                    this.saveAccountMonthStartDay(monthStartDay);
                }
            },

            selectedDivision() {
                this.cancelAll();
                this.$emit('fetch-users');
            },

            'contextAccount.id'(newValue) {
                this.setUsers(newValue);
            },

            selectedMonth() {
                this.cancelAll();
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['appendNewError']),

            setFilteredUsers() {
                this.filteredUsers = (this.users || [])
                    .filter(user => {
                        return user.active && !user.hide_in_user_select && user.divisions.some(division => this.selectedDivision == division.id);
                    })
                    .sort((a, b) => a.first_name.localeCompare(b.first_name));
            },

            cancelAll() {
                if (this.$refs.saleInputs) {
                    this.$refs.saleInputs.forEach(input => {
                        input.cancel();
                    });
                }
                if (this.$refs.currencyInput) {
                    this.$refs.currencyInputs.forEach(input => {
                        input.cancel();
                    });
                }
            },

            async saveAccountMonthStartDay(day) {
                await this.$axios.put(`v1/accounts/${this.contextAccount.id}`, {
                    month_start_day: day,
                });
            },

            setDays() {
                const days = [];

                for (let i = 1; i <= 28; i++) {
                    days.push(i);
                }

                this.days = days;
            },

            completeName(user) {
                return this.getFullName(user);
            },

            percentSale(user) {
                if (this.totalSales > 0) {
                    return `${((this.objective(user).sale_amount / this.totalSales) * 100).toFixed(1)}%`;
                }
                return 0;
            },

            objective(user) {
                const existing = user.objectives.filter(objective => {
                    const definedDate = as_locale(objective.defined_date, 'defined_date');

                    return (
                        this.selectedDivision == objective.division_id &&
                        ((definedDate.month() == this.selectedMonth && definedDate.year() == now().year()) ||
                            (this.selectedMonth == 12 &&
                                definedDate.month() == 0 &&
                                definedDate.year() == now().year() + 1))
                    );
                });

                let objective = {
                    user_id: user.id,
                    sale_amount: 0,
                    average_value: 0,
                    month: this.selectedMonth,
                    id: null,
                    division: this.divisions[this.selectedDivision],
                };

                if (existing.length > 0) {
                    objective = {
                        user_id: user.id,
                        sale_amount: existing[0].sale_amount,
                        average_value: parseInt(existing[0].average_value, 10),
                        month: this.selectedMonth,
                        id: existing[0].id,
                        division: this.divisions[this.selectedDivision],
                    };
                }

                return objective;
            },

            async onAccept(targetUser, name, value) {
                const payload = {};
                const objective = {};

                objective[`${name}`] = typeof value === 'string' ? value.replace(/,/g, '').replace(/\./g, '') : value;
                objective.id = this.objective(targetUser).id;
                objective.division = this.divisions[this.selectedDivision];

                if (parseInt(this.selectedMonth, 10) == 12) {
                    objective.defined_date = now_date().addYears(1).startOfYear().toString();
                } else {
                    objective.defined_date = now_date()
                        .month(parseInt(this.selectedMonth, 10))
                        .startOfMonth()
                        .toString();
                }

                payload._method = 'PUT';
                payload.objective = objective;
                payload.account = this.contextAccount.id;

                // Update lead or vehicle
                try {
                    const response = await this.$api.users.update(targetUser.id, payload);

                    const index = this.filteredUsers.findIndex(user => user.id == targetUser.id);
                    this.$set(this.filteredUsers, index, response);
                } catch (error) {
                    if (error.response && error.response.status === 403) {
                        return;
                    }

                    this.appendNewError({
                        code: '0073',
                        display: true,
                        error,
                        payload,
                    });
                }
            },
        },

        created() {
            this.setDays();
        },

        mounted() {
            this.selectedDivision = Division.NEW.toString();
        },
    };
</script>
